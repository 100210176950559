import { Box, styled } from "@mui/material";
import { ReactNode } from "react";

const BaseSmallOvalButton = styled(Box)({
  height: 30,
  paddingInline: 6, // 横方向の余白
  color: "#424242",
  backgroundColor: "#EEEEEE",
  borderRadius: 1000,
  borderStyle: "solid",
  borderWidth: 1,
  borderColor: "#EEEEEE",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  "&:hover": {
    borderColor: "#424242",
  },
  "&:active": {
    transform: "scale(0.93) !important",
  },
});

type Props = {
  isPressed?: boolean;
  onClick?: () => void;
  children?: ReactNode;
};

export const SmallOvalButton = (props: Props) => (
  <BaseSmallOvalButton
    style={
      props.isPressed
        ? {
            color: "#0288D1",
            backgroundColor: "#E1F5FE",
            borderWidth: 1.5,
            borderColor: "#0288D1",
          }
        : {}
    }
    onClick={props.onClick}
  >
    {props.children}
  </BaseSmallOvalButton>
);
