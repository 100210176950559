import AddReactionOutlinedIcon from "@mui/icons-material/AddReactionOutlined";
import { Box, styled } from "@mui/material";

const SmallOvalButton = styled(Box)({
  height: 30,
  width: 35,
  color: "#EEEEEE",
  backgroundColor: "#EEEEEE",
  borderRadius: 1000,
  borderStyle: "solid",
  borderWidth: 1,
  borderColor: "#EEEEEE",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  "&:hover": {
    borderColor: "#424242",
  },
  "&:active": {
    transform: "scale(0.93) !important",
  },
});

type Props = {
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

export const StampAddButton = ({ onClick }: Props) => {
  return (
    <SmallOvalButton onClick={onClick}>
      <AddReactionOutlinedIcon style={{ width: 18, color: "#424242" }} />
    </SmallOvalButton>
  );
};
