export const getUnixTimeMs = () => {
  const date = new Date();
  const unixTimeMs = date.getTime();
  return unixTimeMs;
};

export const getUnixTime = () => {
  const unixTimeMs = getUnixTimeMs();
  const unixTime = Math.floor(unixTimeMs / 1000);
  return unixTime;
};
