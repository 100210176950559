import axios from "axios";
import { GetStampType, StampAddType } from "./ResponseType";
import { getUnixTimeMs } from "./Utils";

export const getStampCount = async ({ alt }: { alt: string }) => {
  const requestUrl =
    "https://rabbitprogram.com/api/images/getstamp.py?image=" +
    encodeURIComponent(alt) +
    "&nocache=" +
    getUnixTimeMs();
  const response = await axios.get(requestUrl);
  const resultData: GetStampType = response.data;

  if (!resultData.isDone) {
    return {};
  }
  return resultData.result;
};

export const requestStampAdd = async ({
  alt,
  userId,
  stampCode,
}: {
  alt: string;
  userId: string;
  stampCode: string;
}) => {
  const requestUrl =
    "https://rabbitprogram.com/api/images/stampadd.py?image=" +
    encodeURIComponent(String(alt)) +
    "&user=" +
    encodeURIComponent(String(userId)) +
    "&stamp=" +
    encodeURIComponent(String(stampCode)) +
    "&nocache=" +
    getUnixTimeMs();
  const response = await axios.get(requestUrl);
  const resultData: StampAddType = response.data;
  return resultData.isDone;
};

export const requestStampDelete = async ({
  alt,
  userId,
  stampCode,
}: {
  alt: string;
  userId: string;
  stampCode: string;
}) => {
  const requestUrl =
    "https://rabbitprogram.com/api/images/deletestamp.py?image=" +
    encodeURIComponent(alt) +
    "&user=" +
    encodeURIComponent(String(userId)) +
    "&stamp=" +
    encodeURIComponent(String(stampCode)) +
    "&nocache=" +
    getUnixTimeMs();
  const response = await axios.get(requestUrl);
  const resultData: GetStampType = response.data;
  return resultData.isDone;
};
