import "./App.css";
import { Box, Button, CssBaseline, Grid, Typography } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import MailIcon from "@mui/icons-material/Mail";
import { PhotoCard } from "./PhotoCard";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "emoji-mart/css/emoji-mart.css";
import axios from "axios";
import { GenerateUserIdType } from "./ResponseType";
import { useCookies } from "react-cookie";
import { useEffect } from "react";

const dateToFullString = (date: Date) => {
  const dateStr = new Intl.DateTimeFormat("ja-jp", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }).format(date);
  return dateStr;
};

const getUnixTime = () => {
  const date = new Date();
  const unixtime = Math.floor(date.getTime() / 1000);
  return unixtime;
};

const setForeverCookie = ({
  setCookie,
  key,
  data,
}: {
  setCookie: any;
  key: string;
  data: string;
}) => {
  const date = new Date();
  date.setFullYear(date.getFullYear() + 1000); // FIXME: なぜか1年後くらいまでしか延長できない！（原因不明）
  setCookie(key, data, { expires: date, path: "/" });
};

const doGenerateUserId = async ({ userId }: { userId?: string }) => {
  const baseUrl =
    "https://rabbitprogram.com/api/images/generateuserid.py?time=" +
    String(getUnixTime());
  const requestUrl =
    userId && userId.length ? baseUrl + "&id=" + userId : baseUrl;
  const response = await axios.get(requestUrl);
  const resultData: GenerateUserIdType = response.data;
  return resultData;
};

const theme = createTheme({
  palette: {
    primary: {
      light: "#a2cf6e",
      main: "#8bc34a",
      dark: "#618833",
      contrastText: "#fff",
    },
    secondary: {
      light: "#91ff35",
      main: "#76ff03",
      dark: "#52b202",
      contrastText: "#000",
    },
    background: {
      default: "#212121",
    },
    action: {
      // disabledOpacity: "blue",
      disabledBackground: "#616161",
      disabled: "#616161",
    },
  },
});

export const App = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["userId"]);

  // ユーザーIDを生成
  useEffect(() => {
    // 既に登録されている場合は同じユーザーIDが返される
    doGenerateUserId({ userId: cookies.userId }).then(
      (resultData: GenerateUserIdType) => {
        if (!resultData.isDone) {
          return;
        }
        setForeverCookie({ setCookie, key: "userId", data: resultData.id });
      }
    );
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box className="App">
        <Box className="App-header">
          <Typography fontSize="8.5vw" fontFamily="Bahnschrift">
            RabbitImages
          </Typography>
          <Typography fontSize="2.5vw">
            This page is under construction. Coming Soon!
          </Typography>

          {/* <Button
            variant="outlined"
            onClick={() => {
              removeCookie("userId");
            }}
          >
            テストボタン
          </Button> */}

          <Box sx={{ height: 20 }} />

          <Grid container sx={{ width: "fit-content" }} spacing={2}>
            <Grid item>
              <Button
                variant="outlined"
                startIcon={<InstagramIcon />}
                sx={{ textTransform: "none" }}
                disabled
              >
                Instagram
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                startIcon={<MailIcon />}
                sx={{ textTransform: "none" }}
                onClick={() => {
                  window.open("mailto:rabbitprogram.2018@gmail.com", "_blank");
                }}
              >
                Send a Mail
              </Button>
            </Grid>
          </Grid>

          <Box sx={{ height: 50 }} />

          <Grid
            container
            sx={{ width: "fit-content", justifyContent: "center" }}
            spacing={5}
          >
            <PhotoCard
              alt="20220924_094913"
              // title="Do you want to eat this?"
              // linkUrl="https://goo.gl/maps/4NTRTXoQt9ob2MFJ6"
              // linkText="のんほいパーク"
            />
            <PhotoCard
              alt="20220924_122429"
              // title="Show me your face!"
              // linkUrl="https://goo.gl/maps/4NTRTXoQt9ob2MFJ6"
              // linkText="のんほいパーク"
            />
            <PhotoCard
              alt="20220924_154359"
              // title="Pensive Penguin"
              // linkUrl="https://goo.gl/maps/4NTRTXoQt9ob2MFJ6"
              // linkText="のんほいパーク"
            />
            <PhotoCard
              alt="20221008"
              // title="Twilight"
              // linkUrl=""
              // linkText=""
            />
            <PhotoCard
              alt="P1000321"
              // title="Entrance to Heaven"
              // linkUrl=""
              // linkText=""
            />
            <PhotoCard
              alt="P1000395"
              // title=""
              // linkUrl=""
              // linkText=""
            />
            <PhotoCard
              alt="P1001192"
              // title=""
              // linkUrl=""
              // linkText=""
            />
            <PhotoCard
              alt="P1001144"
              // title=""
              // linkUrl=""
              // linkText=""
            />
          </Grid>

          <Box sx={{ height: 50 }} />
        </Box>
      </Box>
    </ThemeProvider>
  );
};
