import { Box, Typography } from "@mui/material";
import { Emoji } from "emoji-mart";
import { SmallOvalButton } from "./SmallOvalButton";

type Props = {
  emojiId: string;
  count: number;
  onClick?: () => void;
  isPressed?: boolean;
};

export const StampButton = (props: Props) => {
  return (
    <SmallOvalButton onClick={props.onClick} isPressed={props.isPressed}>
      <Emoji emoji={props.emojiId} size={18} />
      <Box sx={{ mr: 0.5 }} />
      <Typography
        fontSize={12}
        fontWeight={props.isPressed ? "bold" : "medium"}
      >
        {props.count}
      </Typography>
    </SmallOvalButton>
  );
};
