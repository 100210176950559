import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  SxProps,
  Theme,
  Box,
  Link,
} from "@mui/material";
import { EmojiData, Picker } from "emoji-mart";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import {
  requestStampAdd,
  requestStampDelete,
  getStampCount,
} from "./BackendRequest";
import { StampUserType } from "./ResponseType";
import { StampAddButton } from "./StampAddButton";
import { StampButton } from "./StampButton";

type Props = {
  alt: string;
  title?: string;
  linkUrl?: string;
  linkText?: string;
  children?: ReactNode;
  sx?: SxProps<Theme>;
};

export const PhotoCard = (props: Props) => {
  const [cookies, setCookie, removeCookie] = useCookies(["userId"]);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [stampUser, setStampUser] = useState<StampUserType>({});
  const targetRef = useRef(null);

  // スタンプを付与
  const doStampAdd = (stampCode: string) => {
    // 表示・バックエンドに反映
    const isDone = requestStampAdd({
      alt: props.alt,
      userId: cookies.userId,
      stampCode: stampCode,
    });

    if (!isDone) {
      return;
    }

    // 変数に反映
    const newStampUser = Object.assign({}, stampUser);
    if (
      Object.keys(stampUser).find((nowStampCode) => nowStampCode === stampCode)
    ) {
      newStampUser[stampCode].push(cookies.userId);
    } else {
      newStampUser[stampCode] = [cookies.userId];
    }
    setStampUser(newStampUser);
  };

  // 付与されたスタンプを削除
  const doStampDelete = (stampCode: string) => {
    // 表示・バックエンドに反映
    const isDone = requestStampDelete({
      alt: props.alt,
      userId: cookies.userId,
      stampCode: stampCode,
    });

    if (!isDone) {
      return;
    }

    // 変数に反映
    const newStampUser: StampUserType = {};
    Object.keys(stampUser).forEach((nowStampCode: string) => {
      if (nowStampCode !== stampCode) {
        newStampUser[nowStampCode] = stampUser[nowStampCode].slice();
        return;
      }
      const newUserList = stampUser[nowStampCode].filter(
        (userId: string) => userId !== cookies.userId
      );
      if (newUserList.length) {
        newStampUser[nowStampCode] = newUserList;
      }
    });
    setStampUser(newStampUser);
  };

  const onPickerSelect = (emoji: EmojiData) => {
    setIsPickerOpen(false);
    if (typeof emoji.id === "undefined") {
      return;
    }

    // 既に付与済みの場合は無視
    if (stampUser[emoji.id] && stampUser[emoji.id].includes(cookies.userId)) {
      return;
    }

    doStampAdd(emoji.id);
  };

  const onStampButtonClick = ({ stampCode }: { stampCode: string }) => {
    const isPressed = stampUser[stampCode].includes(cookies.userId);
    if (isPressed) {
      doStampDelete(stampCode);
      return;
    }
    doStampAdd(stampCode);
    setIsPickerOpen(false);
  };

  useEffect(() => {
    getStampCount({ alt: props.alt }).then((result) => {
      setStampUser(result);
    });
  }, []);

  const imageSrc = useMemo(() => {
    try {
      return require("./photos/" + props.alt + ".webp");
    } catch {
      return null;
    }
  }, []);

  return (
    <Grid
      item
      sx={props.sx}
      style={{ position: "relative" }}
      key={"photocatd-" + props.alt}
      ref={targetRef}
    >
      <Card sx={{ maxWidth: 350 }}>
        <CardMedia component="img" src={imageSrc} alt={props.alt} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {props.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {props.linkUrl && props.linkText && (
              <Link
                href={props.linkUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {props.linkText}
              </Link>
            )}
            {props.children}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              columnGap: 0.5,
              rowGap: 0.5,
              mt: 2,
            }}
          >
            {Object.keys(stampUser).map((stampCode: string) => {
              const isPressed = stampUser[stampCode].includes(cookies.userId);
              return (
                <StampButton
                  key={"stampbutton-" + stampCode}
                  emojiId={stampCode}
                  count={stampUser[stampCode].length}
                  onClick={() => onStampButtonClick({ stampCode: stampCode })}
                  isPressed={isPressed}
                />
              );
            })}
            <StampAddButton
              onClick={(e) => {
                if (isPickerOpen) {
                  setIsPickerOpen(false);
                  return;
                }
                setIsPickerOpen(true);
              }}
            />
            <Picker
              style={{
                display: !isPickerOpen ? "none" : "block",
                position: "absolute",
                left: 45,
                top: 60,
                zIndex: 1,
              }}
              onSelect={onPickerSelect}
              set="apple"
            />
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};
